import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { HStack, Text, VStack } from 'react-stacked'

import { ERROR_COLOR } from '../lib/color'

interface DisclaimerErrorProps {
  description?: string
  title?: string
}

const DisclaimerError: React.FC<DisclaimerErrorProps> = ({ description, title }) => {
  return (
    <HStack
      alignItems='center'
      backgroundColor={ERROR_COLOR}
      borderColor='#fff97f'
      borderRadius={24}
      borderStyle='solid'
      borderWidth={1}
      gap={12}
      paddingHorizontal={24}
      paddingVertical={16}
    >
      <MaterialIcons color='#eeeeee' name='error' size={48} />

      <VStack gap={4}>
        {title == null ? null : <Text color='#eeeeee' size={18} weight='bold'>{title}</Text>}

        {description == null ? null : <Text color='#eeeeee' size={16}>{description}</Text>}
      </VStack>
    </HStack>
  )
}

export default DisclaimerError
