import React, { type ReactNode } from 'react'
import { HStack } from 'react-stacked'

import { ERROR_COLOR } from '../lib/color'

interface ListItemWrapperProps {
  backgroundColor?: string
  children: ReactNode
  hasError?: boolean
}

const ListItemWrapper: React.FC<ListItemWrapperProps> = ({ backgroundColor, children, hasError = false }) => {
  return (
    <HStack
      backgroundColor={backgroundColor ?? '#fefefe'}
      borderColor={!hasError ? undefined : ERROR_COLOR}
      borderRadius={5}
      borderStyle={!hasError ? undefined : 'solid'}
      borderWidth={!hasError ? undefined : ERROR_COLOR}
      grow={1}
      justifyContent='space-between'
      paddingHorizontal={8}
      paddingVertical={12}
    >
      {children}
    </HStack>
  )
}

export default ListItemWrapper
